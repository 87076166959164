import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/community.tsx";
export const pageTitle = "Developer guide";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Tip = makeShortcode("Tip");
const Tabs = makeShortcode("Tabs");
const TabPane = makeShortcode("TabPane");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "developer-guide",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#developer-guide",
        "aria-label": "developer guide permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Developer guide`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#build-requirements"
          }}>{`Build requirements`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#how-to-build"
          }}>{`How to build`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#contributor-license-agreement"
          }}>{`Contributor license agreement`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#setting-up-your-ide"
          }}>{`Setting up your IDE`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#configure--parameters-javac-option"
          }}>{`Configure -parameters javac option`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#always-make-the-build-pass"
          }}>{`Always make the build pass`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#add-copyright-header"
          }}>{`Add copyright header`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#add-javadoc"
          }}>{`Add Javadoc`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#check-the-warnings-from-the-inspection-profile"
          }}>{`Check the warnings from the inspection profile`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#avoid-redundancy"
          }}>{`Avoid redundancy`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#use-public-only-when-necessary"
          }}>{`Use public only when necessary`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#organize"
          }}>{`Organize`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#check-null"
          }}>{`Check null`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#use-nullable"
            }}>{`Use @Nullable`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#avoid-redundant-null-checks"
            }}>{`Avoid redundant null checks`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#use-meaningful-exception-messages"
          }}>{`Use meaningful exception messages`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#validate"
          }}>{`Validate`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#use-guavas-preconditions-if-possible"
            }}>{`Use Guava's Preconditions if possible`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#prefer-jdk-api"
          }}>{`Prefer JDK API`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#prefer-early-return-style"
          }}>{`Prefer early-return style`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#prefer-moreobjectstostringhelper"
          }}>{`Prefer MoreObjects.toStringHelper()`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#think-aesthetics"
          }}>{`Think aesthetics`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#use-junit-5-instead-of-junit-4-for-testing"
          }}>{`Use JUnit 5 instead of JUnit 4 for testing`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#use-assertj-instead-of-junits-assertion-api"
          }}>{`Use AssertJ instead of JUnit's assertion API`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#how-to-write-pull-request-description"
          }}>{`How to write pull request description`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#motivation"
            }}>{`Motivation`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#modifications"
            }}>{`Modifications`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#result"
            }}>{`Result`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#integrating-with-gradle-enterprise"
          }}>{`Integrating with Gradle Enterprise`}</a></p>
      </li>
    </ul>
    <h2 {...{
      "id": "build-requirements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#build-requirements",
        "aria-label": "build requirements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Build requirements`}</h2>
    <ul>
      <li parentName="ul">{`OpenJDK 21 or its derivative, such as `}<a parentName="li" {...{
          "href": "https://adoptium.net/"
        }}>{`Temurin`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Consider using a version manager for convenient installation of JDK, such as
`}<a parentName="li" {...{
              "href": "https://asdf-vm.com/"
            }}>{`asdf`}</a>{` and `}<a parentName="li" {...{
              "href": "https://github.com/shyiko/jabba"
            }}>{`jabba`}</a>{`.`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "how-to-build",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-to-build",
        "aria-label": "how to build permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How to build`}</h2>
    <p>{`We use `}<a parentName="p" {...{
        "href": "https://gradle.org/"
      }}>{`Gradle`}</a>{` to build Armeria.
The following command will compile Armeria, run tests and generate JARs:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ ./gradlew --parallel build
`}</code></pre>
    <h2 {...{
      "id": "contributor-license-agreement",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#contributor-license-agreement",
        "aria-label": "contributor license agreement permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Contributor license agreement`}</h2>
    <p>{`When you are sending a pull request and it's a non-trivial change beyond fixing typos, please sign
`}<a parentName="p" {...{
        "href": "https://cla-assistant.io/line/armeria"
      }}>{`the ICLA (individual contributor license agreement)`}</a>{`. Please
`}<a parentName="p" {...{
        "href": "mailto:dl_oss_dev@linecorp.com"
      }}>{`contact us`}</a>{` if you need the CCLA (corporate contributor license agreement).`}</p>
    <h2 {...{
      "id": "setting-up-your-ide",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#setting-up-your-ide",
        "aria-label": "setting up your ide permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Setting up your IDE`}</h2>
    <p>{`You can import Armeria into your IDE (`}<a parentName="p" {...{
        "href": "https://www.jetbrains.com/idea/"
      }}>{`IntelliJ IDEA`}</a>{` or `}<a parentName="p" {...{
        "href": "https://www.eclipse.org/"
      }}>{`Eclipse`}</a>{`) as a Gradle project.`}</p>
    <ul>
      <li parentName="ul">{`IntelliJ IDEA - See `}<a parentName="li" {...{
          "href": "https://www.jetbrains.com/help/idea/gradle.html#gradle_import_project_start"
        }}>{`Importing Project from Gradle Model`}</a></li>
      <li parentName="ul">{`Eclipse - Use `}<a parentName="li" {...{
          "href": "https://marketplace.eclipse.org/content/buildship-gradle-integration"
        }}>{`Buildship Gradle Integration`}</a></li>
    </ul>
    <Tip mdxType="Tip">IntelliJ IDEA is our primary IDE for developing Armeria.</Tip>
    <p>{`Before importing the project, run the `}<inlineCode parentName="p">{`generateSources`}</inlineCode>{` task to generate some source files:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ ./gradlew --parallel -PnoLint generateSources
`}</code></pre>
    <Tip mdxType="Tip">
      <p><inlineCode parentName="p">{`-PnoLint`}</inlineCode>{` disables running lint tools such as Checkstyle and ESLint to save build time.`}</p>
    </Tip>
    <p>{`After importing the project, import the IDE settings as well:`}</p>
    <Tabs mdxType="Tabs">
      <TabPane tab="IntelliJ IDEA" key="idea" mdxType="TabPane">
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/line/armeria/raw/main/settings/intellij_idea/settings.jar"
            }}><inlineCode parentName="a">{`settings.jar`}</inlineCode></a>{` -
See `}<a parentName="li" {...{
              "href": "https://www.jetbrains.com/help/idea/sharing-your-ide-settings.html#7a4f08b8"
            }}>{`Import settings from a ZIP archive`}</a>{`.`}</li>
          <li parentName="ul">{`Make sure to use 'LINE OSS' code style and inspection profile.`}
            <ul parentName="li">
              <li parentName="ul">{`Go to `}<inlineCode parentName="li">{`Preferences`}</inlineCode>{` > `}<inlineCode parentName="li">{`Editors`}</inlineCode>{` > `}<inlineCode parentName="li">{`Code Style`}</inlineCode>{` and set `}<inlineCode parentName="li">{`Scheme`}</inlineCode>{` option to `}<inlineCode parentName="li">{`LINE OSS`}</inlineCode>{`.`}</li>
              <li parentName="ul">{`Go to `}<inlineCode parentName="li">{`Preferences`}</inlineCode>{` > `}<inlineCode parentName="li">{`Editors`}</inlineCode>{` > `}<inlineCode parentName="li">{`Inspections`}</inlineCode>{` and set `}<inlineCode parentName="li">{`Profile`}</inlineCode>{` option to `}<inlineCode parentName="li">{`LINE OSS`}</inlineCode>{`.`}</li>
            </ul>
          </li>
        </ul>
      </TabPane>
      <TabPane tab="Eclipse" key="eclipse" mdxType="TabPane">
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/line/armeria/raw/main/settings/eclipse/formatter.xml"
            }}><inlineCode parentName="a">{`formatter.xml`}</inlineCode></a>{` -
See `}<a parentName="li" {...{
              "href": "https://help.eclipse.org/neon/index.jsp?topic=%2Forg.eclipse.jdt.doc.user%2Freference%2Fpreferences%2Fjava%2Fcodestyle%2Fref-preferences-formatter.htm"
            }}>{`Code Formatter Preferences`}</a>{`.`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/line/armeria/raw/main/settings/eclipse/formatter.importorder"
            }}><inlineCode parentName="a">{`formatter.importorder`}</inlineCode></a>{` -
See `}<a parentName="li" {...{
              "href": "https://help.eclipse.org/neon/index.jsp?topic=%2Forg.eclipse.jdt.doc.user%2Freference%2Fpreferences%2Fjava%2Fcodestyle%2Fref-preferences-organize-imports.htm"
            }}>{`Organize Imports Preferences`}</a>{`.`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/line/armeria/raw/main/settings/eclipse/cleanup.xml"
            }}><inlineCode parentName="a">{`cleanup.xml`}</inlineCode></a>{` -
See `}<a parentName="li" {...{
              "href": "https://help.eclipse.org/neon/index.jsp?topic=%2Forg.eclipse.jdt.doc.user%2Freference%2Fpreferences%2Fjava%2Fcodestyle%2Fref-preferences-cleanup.htm"
            }}>{`Clean Up Preferences`}</a>{`.`}</li>
          <li parentName="ul">{`Configure `}<a parentName="li" {...{
              "href": "https://help.eclipse.org/neon/index.jsp?topic=%2Forg.eclipse.jdt.doc.user%2Freference%2Fpreferences%2Fjava%2Feditor%2Fref-preferences-save-actions.htm"
            }}>{`Java Save Actions Preferences`}</a>{`.`}
            <details parentName="li"><summary parentName="details">{`Click here to see the screenshot.`}</summary>{`
  `}<img parentName="details" {...{
                "src": "https://github.com/line/armeria/raw/main/settings/eclipse/save_actions.png"
              }}></img>
            </details>
          </li>
        </ul>
      </TabPane>
    </Tabs>
    <h2 {...{
      "id": "configure--parameters-javac-option",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#configure--parameters-javac-option",
        "aria-label": "configure  parameters javac option permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Configure `}<inlineCode parentName="h2">{`-parameters`}</inlineCode>{` javac option`}</h2>
    <p>{`You can configure your build tool and IDE to add `}<inlineCode parentName="p">{`-parameters`}</inlineCode>{` javac option.
Please refer to `}<a parentName="p" {...{
        "href": "https://armeria.dev/docs/setup#configure--parameters-javac-option"
      }}>{`Configure `}<inlineCode parentName="a">{`-parameters`}</inlineCode>{` javac option`}</a>{` for more information.`}</p>
    <h2 {...{
      "id": "always-make-the-build-pass",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#always-make-the-build-pass",
        "aria-label": "always make the build pass permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Always make the build pass`}</h2>
    <p>{`Make sure your change does not break the build.`}</p>
    <ul>
      <li parentName="ul">{`Run `}<inlineCode parentName="li">{`./gradlew --parallel build`}</inlineCode>{` locally.`}</li>
      <li parentName="ul">{`It is likely that you'll encounter some Checkstyle or Javadoc errors.
Please fix them because otherwise the build will be broken.`}</li>
    </ul>
    <h2 {...{
      "id": "add-copyright-header",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#add-copyright-header",
        "aria-label": "add copyright header permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add copyright header`}</h2>
    <p>{`All source files must begin with the following copyright header:`}</p>
    <pre><code parentName="pre" {...{}}>{`Copyright $today.year LY Corporation

LY Corporation licenses this file to you under the Apache License,
version 2.0 (the "License"); you may not use this file except in compliance
with the License. You may obtain a copy of the License at:

  https://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
License for the specific language governing permissions and limitations
under the License.
`}</code></pre>
    <h2 {...{
      "id": "add-javadoc",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#add-javadoc",
        "aria-label": "add javadoc permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add Javadoc`}</h2>
    <p>{`All public classes and public or protected methods must have Javadoc,
except the classes under `}<inlineCode parentName="p">{`com.linecorp.armeria.internal`}</inlineCode>{`. Referring to
an internal API from a public API will trigger build failures.`}</p>
    <h2 {...{
      "id": "check-the-warnings-from-the-inspection-profile",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#check-the-warnings-from-the-inspection-profile",
        "aria-label": "check the warnings from the inspection profile permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Check the warnings from the inspection profile`}</h2>
    <ul>
      <li parentName="ul">{`Make sure you are using 'LINE OSS' code style and inspection profile.`}</li>
      <li parentName="ul">{`Evaluate all warnings emitted by the 'LINE OSS' inspection profile.`}
        <ul parentName="li">
          <li parentName="ul">{`Try to fix them all and use the `}<inlineCode parentName="li">{`@SuppressWarnings`}</inlineCode>{` annotation if it's a false positive.`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "avoid-redundancy",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#avoid-redundancy",
        "aria-label": "avoid redundancy permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Avoid redundancy`}</h2>
    <p>{`Avoid using redundant keywords. To list a few:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`final`}</inlineCode>{` method modifier in a `}<inlineCode parentName="li">{`final`}</inlineCode>{` class`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`static`}</inlineCode>{` or `}<inlineCode parentName="li">{`public`}</inlineCode>{` modifier in an `}<inlineCode parentName="li">{`interface`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`public`}</inlineCode>{` method modifier in a package-local or private class`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`private`}</inlineCode>{` constructor modifier in an `}<inlineCode parentName="li">{`enum`}</inlineCode></li>
      <li parentName="ul">{`field access prefixed with `}<inlineCode parentName="li">{`this.`}</inlineCode>{` where unnecessary`}</li>
    </ul>
    <h2 {...{
      "id": "use-public-only-when-necessary",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#use-public-only-when-necessary",
        "aria-label": "use public only when necessary permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Use `}<inlineCode parentName="h2">{`public`}</inlineCode>{` only when necessary`}</h2>
    <p>{`The classes, methods and fields that are not meant to be used by a user should not be
public. Use the most restrictive modifier wherever possible, such as `}<inlineCode parentName="p">{`private`}</inlineCode>{`,
package-local and `}<inlineCode parentName="p">{`protected`}</inlineCode>{`, so that static analysis tools can find dead code easily.`}</p>
    <h2 {...{
      "id": "organize",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#organize",
        "aria-label": "organize permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Organize`}</h2>
    <p>{`Organize class members carefully for readability, using `}<strong parentName="p">{`top-down`}</strong>{` approach.
Although there's no absolute rule of thumb, it's usually like:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`static`}</inlineCode>{` fields`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`static`}</inlineCode>{` methods`}</li>
      <li parentName="ul">{`member fields`}</li>
      <li parentName="ul">{`constructors`}</li>
      <li parentName="ul">{`member methods`}</li>
      <li parentName="ul">{`utility methods (both `}<inlineCode parentName="li">{`static`}</inlineCode>{` and member)`}</li>
      <li parentName="ul">{`inner classes`}</li>
    </ul>
    <h2 {...{
      "id": "check-null",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#check-null",
        "aria-label": "check null permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Check `}<inlineCode parentName="h2">{`null`}</inlineCode></h2>
    <p>{`Do explicit `}<inlineCode parentName="p">{`null`}</inlineCode>{`-check on the parameters of user-facing public methods.
Always use `}<inlineCode parentName="p">{`Objects.requireNonNull(Object, String)`}</inlineCode>{` to do a `}<inlineCode parentName="p">{`null`}</inlineCode>{`-check.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import static java.util.Objects.requireNonNull;

public void setProperty(String name, String value) {
    // Great
    this.name = requireNonNull(name, "name");
    // Not great - we may not know which parameter is null exactly. 
    this.name = requireNonNull(name);
    // Not great - too verbose. NPE implies something's null already.
    this.name = requireNonNull(name, "name is null");
    // Not OK
    this.name = name
}
`}</code></pre>
    <p>{`If you are using IntelliJ IDEA and you imported the `}<inlineCode parentName="p">{`settings.jar`}</inlineCode>{` as explained
above, try the live template `}<inlineCode parentName="p">{`rnn`}</inlineCode>{` and `}<inlineCode parentName="p">{`rnna`}</inlineCode>{` which will save a lot of time.`}</p>
    <h3 {...{
      "id": "use-nullable",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#use-nullable",
        "aria-label": "use nullable permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Use `}<inlineCode parentName="h3">{`@Nullable`}</inlineCode></h3>
    <p>{`Use `}<inlineCode parentName="p">{`@Nullable`}</inlineCode>{` annotation for nullable parameters and return types.
Do not use `}<inlineCode parentName="p">{`@Nonnull`}</inlineCode>{` annotation since we assume everything is non-null otherwise.`}</p>
    <h3 {...{
      "id": "avoid-redundant-null-checks",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#avoid-redundant-null-checks",
        "aria-label": "avoid redundant null checks permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Avoid redundant null checks`}</h3>
    <p>{`Avoid unnecessary `}<inlineCode parentName="p">{`null`}</inlineCode>{`-checks, including the hidden checks in `}<inlineCode parentName="p">{`Objects.hashCode()`}</inlineCode>{` and `}<inlineCode parentName="p">{`Objects.equals()`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public final class MyClass {
    private final String name;

    public MyClass(String name) {
        // We are sure 'name' is always non-null.
        this.name = requireNonNull(name, "name");
    }

    @Override
    public int hashCode() {
        // OK
        return name.hashCode();
        // Not OK
        return Objects.hash(name);
    }

    @Override
    public boolean equals(@Nullable Object obj) {
        ... usual type check ...
        // OK
        return name.equals(((MyClass) obj).name);
        // Not OK
        return Objects.equals(name, ((MyClass) obj).name);
    }
}
`}</code></pre>
    <h2 {...{
      "id": "use-meaningful-exception-messages",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#use-meaningful-exception-messages",
        "aria-label": "use meaningful exception messages permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Use meaningful exception messages`}</h2>
    <p>{`When raising an exception, specify meaningful message which gives an explicit clue
about what went wrong.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`switch (fileType) {
    case TXT: ... break;
    case XML: ... break;
    default:
        // Note that the exception message contains the offending value
        // as well as the expected values.
        throw new IllegalStateException(
                "unsupported file type: " + fileType +
                 " (expected: " + FileType.TXT + " or " + FileType.XML + ')');
}
`}</code></pre>
    <h2 {...{
      "id": "validate",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#validate",
        "aria-label": "validate permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Validate`}</h2>
    <p>{`Do explicit validation on the parameters of user-facing public methods.
When raising an exception, always specify the detailed message in the following format:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public void setValue(int value) {
    if (value < 0) {
        // Note that the exception message contains the offending value
        // as well as the expected value.
        throw new IllegalArgumentException("value: " + value + " (expected: >= 0)");
    }
}
`}</code></pre>
    <h3 {...{
      "id": "use-guavas-preconditions-if-possible",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#use-guavas-preconditions-if-possible",
        "aria-label": "use guavas preconditions if possible permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Use Guava's `}<inlineCode parentName="h3">{`Preconditions`}</inlineCode>{` if possible`}</h3>
    <p>{`Guava's `}<inlineCode parentName="p">{`Preconditions`}</inlineCode>{` provides `}<inlineCode parentName="p">{`checkArgument()`}</inlineCode>{` and `}<inlineCode parentName="p">{`checkState()`}</inlineCode>{` which can simplify argument or state validation logic:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`private boolean someState;
void doSomething(int value) {
    checkArgument(value > 0, "value: %s (expected: > 0)", value);
    checkState(someState, "Cannot be called when ...");
    ...
}
`}</code></pre>
    <p>{`However, you should use a good old `}<inlineCode parentName="p">{`if`}</inlineCode>{` block if it takes an extra job to create an exception message:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`if (value <= 0) {
    throw new IllegalArgumentException(
        "value: " + toHumanReadable(value) + " (expected: ...)");
}
`}</code></pre>
    <h2 {...{
      "id": "prefer-jdk-api",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#prefer-jdk-api",
        "aria-label": "prefer jdk api permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Prefer JDK API`}</h2>
    <p>{`Prefer using plain JDK API when the same behavior can be achieved with the same
amount of code.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`// Prefer A (JDK) - less indirection
Map<String, String> map = new HashMap<>();   // A (JDK)
Map<String, String> map = Maps.newHashMap(); // B (Guava)

// Prefer B (Guava) - simpler yet more efficient
List<String> list = Collections.unmodifiableList(  // A (JDK)
        otherList.stream().filter(...).collect(Collectors.toList()));
List<String> list = otherList.stream().filter(...) // B (Guava)
        .collect(toImmutableList());
`}</code></pre>
    <h2 {...{
      "id": "prefer-early-return-style",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#prefer-early-return-style",
        "aria-label": "prefer early return style permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Prefer early-return style`}</h2>
    <p>{`Prefer 'early return' code style for readability.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`// Great
public void doSomething(String value) {
    if (value == null) {
        return;
    }

    // Do the actual job
}

// Not great
public void doSomething(String value) {
    if (value != null) {
        // Do the actual job
    }
}
`}</code></pre>
    <p>{`However, when the 'normal' execution path is very simple, this may also look beautiful:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`@Nullable
public String doSomething(String value) {
    if (value != null) {
        return value.trim();
    } else {
        return null;
    }
}
`}</code></pre>
    <h2 {...{
      "id": "prefer-moreobjectstostringhelper",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#prefer-moreobjectstostringhelper",
        "aria-label": "prefer moreobjectstostringhelper permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Prefer `}<inlineCode parentName="h2">{`MoreObjects.toStringHelper()`}</inlineCode></h2>
    <p>{`Prefer `}<inlineCode parentName="p">{`MoreObjects.toStringHelper()`}</inlineCode>{` to hand-written `}<inlineCode parentName="p">{`toString()`}</inlineCode>{` implementation.
However, consider writing hand-written or caching `}<inlineCode parentName="p">{`toString()`}</inlineCode>{` implementation
in performance-sensitive places.  `}</p>
    <h2 {...{
      "id": "think-aesthetics",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#think-aesthetics",
        "aria-label": "think aesthetics permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Think aesthetics`}</h2>
    <p>{`Do not insert an empty line that hurts code aesthetics.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`// OK
if (...) {
    doSomething();
}

// Not OK
if (...) {
    doSomething();
                        // <-- Remove this extra line.
}
`}</code></pre>
    <p>{`Similarly, do not use two or more consecutive empty lines.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`// OK
public void a() { ... }

public void b() { ... }

// Not OK
public void a() { ... }

                        // <-- Remove this extra line.
public void b() { ... }
`}</code></pre>
    <h2 {...{
      "id": "use-junit-5-instead-of-junit-4-for-testing",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#use-junit-5-instead-of-junit-4-for-testing",
        "aria-label": "use junit 5 instead of junit 4 for testing permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Use JUnit 5 instead of JUnit 4 for testing`}</h2>
    <p>{`We support both `}<a parentName="p" {...{
        "href": "https://junit.org/junit4/"
      }}>{`JUnit 4`}</a>{` and `}<a parentName="p" {...{
        "href": "https://junit.org/junit5/"
      }}>{`JUnit 5`}</a>{` for testing, but we recommend to use JUnit 5.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`// Imports of JUnit5, Good
import org.junit.jupiter.api.AfterEach;
import org.junit.jupiter.api.BeforeEach;
import org.junit.jupiter.api.Test;
...

// Imports of JUnit4, Not Good
import org.junit.After;
import org.junit.Before;
import org.junit.Test;
...
`}</code></pre>
    <h2 {...{
      "id": "use-assertj-instead-of-junits-assertion-api",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#use-assertj-instead-of-junits-assertion-api",
        "aria-label": "use assertj instead of junits assertion api permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Use AssertJ instead of JUnit's assertion API`}</h2>
    <p>{`We prefer `}<a parentName="p" {...{
        "href": "https://joel-costigliola.github.io/assertj/"
      }}>{`AssertJ`}</a>{` when writing assertions for test cases.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`// Good
assertThat(actualValue).isEqualTo(expectedValue);
assertThatThrownBy(() -> badMethod()).isInstanceOf(IllegalArgumentException.class)
                                     .hasMessageContaining("bad method");
// Not Good
assertEquals(expectedValue, actualValue);
try {
    badMethod();
} catch (IllegalArgumentException e) {
    assertTrue(e.getMessage().contains("bad method"));
}
`}</code></pre>
    <h2 {...{
      "id": "how-to-write-pull-request-description",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-to-write-pull-request-description",
        "aria-label": "how to write pull request description permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How to write pull request description`}</h2>
    <p>{`Writing a good pull request description is important to both contributors and reviewers because:`}</p>
    <ul>
      <li parentName="ul">{`it enables a contributor to communicate the intention and context of a pull request more clearly with reviewers.`}</li>
      <li parentName="ul">{`it helps the developers write good `}<a parentName="li" {...{
          "href": "/release-notes/"
        }}>{`release notes`}</a>{`.`}</li>
    </ul>
    <p>{`How much detail should a pull request description have? The general rule of thumb is
to put all `}<em parentName="p">{`notable`}</em>{` changes in detail. It doesn't have to contain every single tiny
detail of the changes.
Usually, you need to fill the following 3 sections: Motivation, Modifications and Result.`}</p>
    <h3 {...{
      "id": "motivation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#motivation",
        "aria-label": "motivation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Motivation`}</h3>
    <p>{`Explain why you're sending the pull request and what problem you're trying to solve.
You do not have to include all the detail but please include as much as the reviewers can get the background
of this change.
If there are related GitHub issues, please leave links to them.
If you referred other resources (e.g. RFCs) for making this change, please leave links to them as well.`}</p>
    <p>{`These are good examples:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/line/armeria/pull/3479#issue-621862219"
        }}>{`#3479 (comment)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/line/armeria/pull/3331#issue-567485208"
        }}>{`#3331 (comment)`}</a></li>
    </ul>
    <h3 {...{
      "id": "modifications",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#modifications",
        "aria-label": "modifications permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Modifications`}</h3>
    <p>{`List the modifications you've made in detail.
Again, you do not have to include all the modifications you made but `}<em parentName="p">{`notable`}</em>{` changes that you wish the
readers know.
If the pull request has a deprecation or breaking change, you need to describe it here. For example:`}</p>
    <pre><code parentName="pre" {...{}}>{`- (Deprecated) Foo.bar() is deprecated.
  - Use Baz.bar() instead.
`}</code></pre>
    <h3 {...{
      "id": "result",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#result",
        "aria-label": "result permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Result`}</h3>
    <p>{`Specify `}<inlineCode parentName="p">{`- Closes #<GitHub issue number>`}</inlineCode>{` if this resolves the issue.
Describe the consequences that a user will face after this pull request is merged.`}</p>
    <p>{`For example, if the pull request fixes a bug, you can write: `}<inlineCode parentName="p">{`You no longer see a Foo exception when using Bar.`}</inlineCode>{`
If you introduce a new feature, you can write: `}<inlineCode parentName="p">{`You can now do A using B.`}</inlineCode>{`
These are examples:`}</p>
    <ul>
      <li parentName="ul">{`You no longer see a `}<inlineCode parentName="li">{`NullPointerException`}</inlineCode>{` when a request times out.`}</li>
      <li parentName="ul">{`You can now monitor the state of all live threads and heap using `}<inlineCode parentName="li">{`ManagementService`}</inlineCode>{`.`}</li>
    </ul>
    <p>{`Add an example snippet if this pull request introduces a new feature, so we can use it in
our `}<a parentName="p" {...{
        "href": "https://armeria.dev/release-notes/"
      }}>{`release notes`}</a>{`, e.g.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`Server.builder()
      .serviceUnder("/internal/management/", ManagementService.of());
`}</code></pre>
    <h2 {...{
      "id": "integrating-with-gradle-enterprise",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#integrating-with-gradle-enterprise",
        "aria-label": "integrating with gradle enterprise permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Integrating with Gradle Enterprise`}</h2>
    <p>{`You may want to integrate your local development environment with `}<a parentName="p" {...{
        "href": "https://gradle.com/"
      }}>{`Gradle Enterprise`}</a>{`.
By doing so, you may:`}</p>
    <ol>
      <li parentName="ol">{`Visualize and troubleshoot build issues more easily.`}</li>
      <li parentName="ol">{`Experience faster builds due to build caches.`}</li>
    </ol>
    <p>{`Let us know at the `}<a parentName="p" {...{
        "href": "/s/discord"
      }}>{`Armeria Discord`}</a>{` channel, and we'll create an account
for you. Afterwards, you may integrate your local environment with the following command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`./gradlew provisionGradleEnterpriseAccessKey
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      